import 'firebase/auth';
import router from '@/router';
import httpStatusCode from 'http-status-codes';
import { use } from 'vee-validate/dist/vee-validate.minimal.esm';
import axios from '@/axios';
import jwt from '../../http/requests/auth/jwt/index.js';

export default {
  fetchDrugEntries({ commit }, data) {
    return new Promise((resolve, reject) => {
      let path = '/api/v1/drug_entries';

      axios
        .get(path, {
          params: {
            clinicId: data.selectedClinic,
            productId: data.productId,
            month: data.month,
            year: data.year,
            page: data.page,
            limit: data.limit,
            sort: data.sort,
            order: data.order,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchDrugEntriesPDF({ commit }, data) {
    return new Promise((resolve, reject) => {
      let path = '/api/v1/drug_entries/pdf';
      axios
        .get(path, {
          params: {
            clinicId: data.selectedClinic,
            productId: data.productId,
            month: data.month,
            year: data.year,
            page: data.page,
            limit: Number.MAX_SAFE_INTEGER,
            isDrugbook: data.isDrugbook,
            isCurrentDateMinus30Days: data.isCurrentDateMinus30Days
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  exportDrugEntriesCustomPDF({ commit }, data) {
    return new Promise((resolve, reject) => {
      let path = '/api/v1/drug_entries/export-custom-pdf?organizationId='+data.organizationId;
      axios
        .post(path,  {
            clinicId: data.clinicId,
            productId: data.productIds,
            startDate: data.startDate,
            endDate: data.endDate
          },
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addStockReceived({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/drug_entries?organizationId=${data.organizationId}`, data.drugEntries)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addStockReceivedOne({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/drug_entries?clinicId=${data[0].clinicId}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateDrugEntry({ commit }, data) {
    /* clinicId: parentEntry.clinicId,
    entryDate,
    productId,
    amount,
    parentDrugEntryId,
    performedBy: parentEntry?.performedBy ?? null,
    appointmentId: parentEntry?.appointmentId ?? null,
    type: parentEntry.type */
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/drug_entries`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editDrugEntry({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/drug_entries/update`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  discardDrug({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/drug_entries/discard?clinicId=${data[0].clinicId}`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getFirstEntryDate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/drug_entries/first-entry-date?clinicId=${data.clinicId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDrugbookAppointmentDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/drug_entries/appointment/detail/${data.appointmentId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAmountPerClinic({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/drug_entries/get-amount-per-clinic?clinicId=${data.clinicId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchClinicsDugs({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/drug_entries/products?clinicId=${data.clinicId}&s=${data.search || ''}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAssociatedClinics({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        // .get(`/api/v1/drug_entries/get-clinics?userId=${data.userId}&orgId=${data.orgId}`)
        .get(`/api/v1/drug_entries/get-clinics/${data.userId}/${data.orgId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchDrugbookTotalBalance({ commit }, data) {
    return new Promise((resolve, reject) => {
      let path = "/api/v1/drug_entries/getTotalBalance";

      axios
        .get(path, {
          params: {
            clinicId: data,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCustomProducts({ commit }, orgId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/drug_entries/get-custom-product/${orgId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCustomPrescribers({ commit }, orgId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/drug_entries/get-custom-prescriber/${orgId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addCustomRecord({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/drug_entries/use-web`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCompletionPercentage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/drug_entries/completion-percentage", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  checkClinicDrugEntries({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/drug_entries/check-drug-entries?organizationId=${data}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};
