import axios from "@/axios";

export default {
  fetchAdmins({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/admins/adminDetails?page=" +
            data.page +
            "&limit=" +
            data.limit +
            "&search=" +
            data.search +
            "&sort=" +
            data.sort +
            "&dir=" +
            data.dir +
            "&userType=" +
            data.userType
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAdminDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/admins/adminDetails/" + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateAdminDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/adminDetails/update/" + data.id, data.info)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/register", data.info)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveNewAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/create", data.info)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteAdmin({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/v1/deleteUser/" + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  checkEditEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/checkEmailForEdit", data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  changeProfileImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/uploadImage", data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetAdminPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/user/" + data.id + "/resetPassword", data.data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  getSuperAdminDashboardData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/admins/superAdminDashboardData")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSafetyVideoDetail({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/admins/getSafetyVideo`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateSafetyVideo({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/updateSafetyVideo", data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  uploadSafetyVideo({ commit }, video) {
    const data = new FormData();
    data.append("uploadVideo", video);
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/uploadSafetyVideo", data, {
          header: {
            "Content-Type": "image/png",
          },
        })
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  deleteSafetyVideo({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/admins/removeSafetyVideo/` + id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSettings() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/admins/getSettings`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveSettings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/admins/saveSettings`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchUserPermission({commit}, data){
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/admins/user-permission?userId="+
          data.userId +
          "&organizationId=" +
          data.organizationId
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
  updateUserPermission({commit}, data){
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/admins/user-permission`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
  checkPOSEnabledClinicsFromOrg({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/admins/checkPOSEnabledClinics?organizationId=`+data.organizationId)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sortCategoryProductList({commit}, data){
    return new Promise((resolve, reject) => {
      axios
      .post(`/api/v1/admins/category/sortCategoryProductList`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
  },
  sortBrandProductList({commit}, data){
    return new Promise((resolve, reject) => {
      axios
      .post(`/api/v1/admins/brand/sortProductList`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
  },
  fetchEventBriteList({ commit }, data){
    return new Promise((resolve, reject) => {
      axios
      .get(`/api/v1/admins/eventbrite`, { params: data })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
  },
  fetchAftercareTemplateList(){
    return new Promise((resolve, reject) => {
      axios
      .get(`/api/v1/admins/aftercaretemplate`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
  },
  updateEventBrite({ commit }, id){
    return new Promise((resolve, reject) => {
      axios
      .put(`/api/v1/admins/eventbrite/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
    })
  },
  getSettingsByKey({commit}, key) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/admins/getSettingsByKey?key=${key}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateSettingsByKey({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/v1/admins/settings', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
