import "firebase/auth";
import router from "@/router";
import httpStatusCode from "http-status-codes";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";
import axios from "@/axios";
import jwt from "../../http/requests/auth/jwt/index.js";

export default {
  fetchOrganizations({ commit }, data) {
    return new Promise((resolve, reject) => {
      let fetchOrganizationUrl =
        "/api/v1/organization/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&filter=" +
        data.filter;

      axios
        .get(fetchOrganizationUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchAssociatedOrganizations({ commit }, data) {
    return new Promise((resolve, reject) => {
      let fetchOrganizationUrl =
        "/api/v1/organizations/" +
        data.id +
        "?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort;

      axios
        .get(fetchOrganizationUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchOrganizationDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/organization/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchOrganizationUserRoles({ commit }, { organizationId, userId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/organization/${organizationId}/user/role/${userId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchOrganizationAllClinics({ commit }, id) {
    let path = "/api/v1/organization/" + id + "/clinics/list";
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchAsociatedOrganizationAllClinics({ commit }, data) {
    let path = "/api/v1/organization/" + data.id + "/clinics/list/"+ data.role+`${data.userId?"?userId="+data.userId:''}`;
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateOrganizationDetails({ commit }, payload) {
    let path = "/api/v1/organization/" + payload.id;
    return new Promise((resolve, reject) => {
      axios
        .patch(path, payload.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchOrganizationOrders({ commit }, data) {
    let path = "/api/v1/organization/" + data.id + "/orders";
    return new Promise((resolve, reject) => {
      axios
        .get(path, {
          params: {
            page: data.page,
            limit: data.limit,
            search: data.search,
            sort: data.sort,
            year: data.year,
            month: data.month,
            clinic: data.clinic,
            status: data.status
          }
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchOrganizationInvoices({ commit }, data) {
    let path = "/api/v1/organization/" + data.organizationId + "/invoices";
    return new Promise((resolve, reject) => {
      axios
        .get(path, {
          params: {
            page: data.page,
            limit: data.limit,
            search: data.search,
            sort: data.sort,
            year: data.year,
            month: data.month,
            clinic: data.clinic,
            status: data.status
          }
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchOrganizationClinicsList({ commit }, data) {
    let path = "/api/v1/organization/" + data.id + "/clinics";
    return new Promise((resolve, reject) => {
      axios
        .get(path, {
          params: {
            page: data.page,
            limit: data.limit,
            search: data.search,
            sort: data.sort,
            dir: data.dir ? data.dir : "asc",
            clinicId: data.clinicId
          }
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchOrganizationUsers({ commit }, data) {
    let path = "/api/v1/organization/" + data.id + "/users";
    return new Promise((resolve, reject) => {
      axios
        .get(path, {
          params: {
            page: data.page,
            limit: data.limit,
            search: data.search,
            clinic: data.clinic,
            userType: data.userType,
            role: data.role,
            sort: data.sort
          }
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchOrganizationClinics({ commit }, data) {
    let path = "/api/v1/organization/" + data.id + "/clinics";
    return new Promise((resolve, reject) => {
      axios
        .get(path, {
          params: {
            page: data.page,
            limit: data.limit,
            search: data.search,
            clinic: data.clinic,
            userType: data.userType,
            sort: data.sort
          }
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinics({ commit }, type) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/franchises/fetchClinics", { params: type })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchSuperAdminOrganizationClinics({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/organization/superAdminOrganizationClinics/"+data.organizationId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createOrganizationBasic({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/organization/create`, payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchOrganizationTreatments({ commit }, data) {
    let path = "/api/v1/organization/" + data.id + "/treatments";
    return new Promise((resolve, reject) => {
      axios
        .get(path, { params: data })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchAllTreatments({ commit }, data) {
    let path = "/api/v1/organization/all-treatments";
    return new Promise((resolve, reject) => {
      axios
        .get(path, { params: data })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  assignOrganizationOwner({ commit }, payload) {
    let path = "/api/v1/organization/" + payload.id + "/assignOwner";
    return new Promise((resolve, reject) => {
      axios
        .post(path, payload.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  assignOrganizationStaff({ commit }, payload) {
    let path =
      "/api/v1/organization/" + payload.orgId + "/assign/" + payload.userId;
    return new Promise((resolve, reject) => {
      axios
        .post(path)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchUsers({ commit }, data) {
    let path = "/api/v1/organization/" + data.id + "/assignOwner/users";
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchOrganizationAssignList({ commit }, data) {
    let path = "/api/v1/organization/assign/" + data.id + "/list";
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchOwnerDetails({ commit }, data) {
    let path = "/api/v1/organization/" + data.id + "/owner";
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createOrganizationClinic({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/organization/clinic/create`, payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAllSupplierList({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/v1/suppliers")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getInstantSupplierList({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpAllInstantSupplierList()
        .then(res => {
          return resolve(res.data.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getAllDiscountList({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/v1/discounts")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // FROM FRANCHISE ACTIONS
  fetchTreatmentAreas({ commit }, dataTableParams) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/organization/" + dataTableParams.organizationId + "/treatments/applicableAreas", {
          params: dataTableParams
        })
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  changeTreatmentAreaStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          "/api/v1/organization/" + payload.organizationId + "/treatments/applicableAreas/changeStatus",
          payload
        )
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  fetchProductsList({ commit }, dataTableParams) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/organization/" + dataTableParams.organizationId + "/products/productsList", {
          params: dataTableParams
        })
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  changeProductStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          "/api/v1/organization/" + payload.organizationId + "/products/productsList/changeStatus",
          payload
        )
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  fetchOrganizationWithDrugEntries({commit}, payload){
    return new Promise((resolve, reject) => {
      axios
        .get("api/v1/organization/drugbook/fetchOrgWithDrugEntries")
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  },
  fetchOrganizationStaffWithStorePermission({commit}, data){
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/organization/staff/with-store-permission/"+data.organizationId,
          {
            params: {
              search: data.search
            }
          }
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
