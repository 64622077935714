import store from "../store/store";
import axios from "@/axios";

export default [
  {
    path: "/org-owner",
    redirect: process.env.VUE_APP_REGION === "AU" ? "/org-owner/home" : "/org-owner/notice-board",
    component: () => import("@/layouts/main/org-owner/orgOwnerMain.vue"),
    meta: {
      rule: "editor",
    },
    children: [
      /*CUSTOM CONSENT ROUTE STARTED*/
      {
        path: "settings/consent-forms",
        name: "customConsent",
        component: () =>
          import("@/views/franchise/clinics/customConsent.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Consent Form",
          rule: "editor",
        },
      },

      /*CREATE CUSTOM CONSENT ROUTE STARTED*/
      {
        path: "settings/custom-consent/create",
        name: "createCustomConsent",
        component: () =>
          import("@/views/franchise/clinics/createCustomConsent.vue"),
        props: (route) => ({
          ...route.params,
        }),
        meta: {
          pageTitle: "Fresh Clinics | Consent Form",
          rule: "editor",
        },
      },
      {
        path: "financial-assistance",
        name: "financial-assistance",
        component: () => import("@/views/general-component/FinancialAssistance.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Financial Assistance",
          rule: "editor",
        },
      },
      {
        path: "billing-settings",
        name: "org-owner-billing-settings",
        component: () => import("@/views/pages/billingSettings.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/clinics" },
          //   { title: "Profile", active: true },
          // ],
          pageTitle: "Fresh Clinics | Billing Settings",
          rule: "editor",
        },
      },
      {
        path: "profile",
        name: "org-owner-profile",
        component: () => import("@/views/pages/adminProfile.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Profile", active: true },
          ],
          pageTitle: "Fresh Clinics | My Account",
          rule: "editor",
        },
      },
      {
        path: "change-password",
        name: "org-owner-change-password",
        component: () => import("@/views/pages/changePassword.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Change Password", active: true },
          ],
          pageTitle: "Fresh Clinics | Change Password",
          rule: "editor",
        },
      },
      {
        path: "nurse-dashboard",
        name: "nurse-dashboard",
        component: () => import("@/views/superAdmin/clinic-nurse-dashboard/ClinicNurseDashboard.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Home",
          rule: "editor",
        },
        beforeEnter: async (to, from, next) => {
          try {
            const userId = store.state.AppActiveUser.id;
            const organizationId = localStorage.getItem(
              `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
            );
            const token = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`);
            const authHeader = {
              Authorization: token
            }

            const { data: orgData } = await axios.get(`/api/v1/organization/${organizationId}`, {
              headers: authHeader
            })
            const { data: permissionData } = await axios.get(`/api/v1/admins/user-permission?userId=${userId}&organizationId=${organizationId}`, {
              headers: authHeader
            })

            const hasPermission = permissionData
              .filter((item) => item.permission.name === "Insights");

            if (!orgData.data.isAnalyticsEnabled) {
              next({ path: "/org-owner/notice-board" });
            } else if (hasPermission.length && orgData.data.isAnalyticsEnabled) {
              next({ path: "/org-owner/home" });
            } else {
              next();
            }
          } catch (error) {
            console.log(`Error fetching: ${error}`)
            next({ path: from.path });
          }
        }
      },
      {
        path: "home",
        name: "Insights",
        component: () => import("@/views/superAdmin/clinic-dashboard-v2/ClinicDashboard.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Home",
          rule: "editor",
          permission: "Insights",
        },
        beforeEnter: async (to, from, next) => {
          try {
            const userId = store.state.AppActiveUser.id;
            const organizationId = localStorage.getItem(
              `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
            );
            const token = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`);
            const authHeader = {
              Authorization: token
            }

            const { data: orgData } = await axios.get(`/api/v1/organization/${organizationId}`, {
              headers: authHeader
            })
            const { data: permissionData } = await axios.get(`/api/v1/admins/user-permission?userId=${userId}&organizationId=${organizationId}`, {
              headers: authHeader
            })

            const hasPermission = permissionData
              .filter((item) => item.permission.name === "Insights");

            if (hasPermission.length && orgData.data.isAnalyticsEnabled) {
              next();
            } else if (process.env.VUE_APP_REGION === "AU" && !hasPermission.length && orgData.data.isAnalyticsEnabled) {
              next({ path: '/org-owner/nurse-dashboard' })
            } else {
              next({ path: "/org-owner/notice-board" });
            }
          } catch (error) {
            console.log(`Error fetching: ${error}`)
            next({ path: from.path });
          }
        },
      },
      {
        path: "notice-board",
        name: "notice-board",
        component: () => import("@/views/basic/Dashboard.vue"),
        meta: {
          pageTitle: "Fresh Clinics | My Organisations",
          rule: "editor",
        },
      },
      {
        path: "notifications",
        name: "nurseNotification",
        component: () =>
          import("@/views/org-owner/notifications/notifications.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "nurse/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Notifications | List",
          rule: "editor",
        },
      },
      {
        path: "clinics",
        name: "OrgOwnerClinics",
        component: () => import("@/views/org-owner/clinics/list.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/dashboard" },
            { title: "Clinics", active: true },
          ],
          pageTitle: "Fresh Clinics | Clinics",
          rule: "editor",
        },
      },
      {
        path: "clinic/:clinicId/edit",
        name: "OrgOwnerEditClinic",
        component: () => import("@/views/org-owner/clinics/editClinic.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/dashboard" },
          //   { title: "Invoices", active: true },
          // ],
          pageTitle: "Fresh Clinics | Org Owner | Clinics | Edit",
          rule: "editor",
        },
      },
      {
        path: "patients",
        name: "OrgOwnerPatients",
        component: () => import("@/views/org-owner/patients/list.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Org Owner | Patients",
          rule: "editor",
        },
      },
      {
        path: "patients/:patientId",
        name: "OrgOwnerPatientDetail",
        component: () =>
          import("@/views/org-owner/patients/PatientDetails.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "org-owner/clinics" },
            { title: "Patients", url: "/org-owner/patients" },
            { title: "Detail", active: true },
          ],
          pageTitle: "Fresh Clinics | Patients | Detail",
          rule: "editor",
        },
      },
      {
        path: "patients/:org-owner/treatment/:checkinId",
        name: "OrgOwnerPatientTreatmentDetail",
        component: () =>
          import("@/views/franchise/patient/PatientTreatmentDetails.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "org-owner/clinics" },
            { title: "Patients", url: "/org-owner/patients" },
            { title: "Treatment Detail", active: true },
          ],
          pageTitle: "Fresh Clinics | Patients | Treatment Detail",
          rule: "editor",
        },
      },
      {
        path: "staff",
        name: "staff",
        component: () => import("@/views/organization/users/index.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Staff", active: true },
          ],
          pageTitle: "Fresh Clinics | Staff",
          rule: "editor",
        },
      },
      {
        path: "pos",
        name: "OrgOwnerPOS",
        component: () =>
          import("@/views/nurse/pos/nursePos.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/clinics" },
          //   { title: "List", active: true },
          // ],
          pageTitle: "Fresh Clinics | POS",
          rule: "editor",
        },
      },
      {
        path: "invoices",
        name: "invoices",
        component: () => import("@/views/organization/invoices/List.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Invoices", active: true },
          ],
          pageTitle: "Fresh Clinics | Invoices",
          rule: "editor",
        },
      },
      {
        path: "events",
        name: "events",
        component: () => import("@/views/basic/Events.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Events",
          rule: "editor",
        },
      },
      {
        path: "supplier-training",
        name: "supplier-training",
        component: () => import("@/views/basic/SupplierTraining.vue"),
        meta: {
        pageTitle: "Fresh Clinics | Supplier Training",
        rule: "editor",
        },
      },
      {
        path: "resources",
        name: "resources",
        component: () => import("@/views/nurse/Resources.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      {
        path: "resources/search",
        name: "org-owner-search-resources",
        component: () => import("@/views/nurse/ResourceSearch.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      {
        path: "compliance",
        name: "OrgOwnerCompliance",
        component: () =>
          import("@/views/org-owner/compliance/Compliance.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/review",
        name: "OrgOwnerComplianceReview",
        component: () => import("@/views/org-owner/compliance/Review.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Compliance", url: "/org-owner/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/step/image/upload",
        name: "OrgOwnerComplianceStepImageUpload",
        component: () =>
          import("@/views/org-owner/compliance/complianceUpload.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Compliance", url: "/org-owner/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/upload",
        name: "OrgOwnerComplianceStepUpload",
        component: () =>
          import("@/views/org-owner/compliance/complianceVideoUpload.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Compliance", url: "/org-owner/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/step/booking",
        name: "orgOwnerComplianceStepBooking",
        component: () =>
          import("@/views/org-owner/compliance/schedulebooking.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Compliance", url: "/org-owner/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance | Booking",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/reschedule/booking",
        name: "orgOwnerComplianceRescheduleBooking",
        component: () =>
          import("@/views/org-owner/compliance/rescheduleBooking.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Compliance", url: "/org-owner/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance | Reschedule Booking",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/stocktake/:complianceId",
        name: "orgOwnerCompliancedrugbookStocktake",
        component: () =>
          import("@/views/org-owner/compliance/complianceCheck.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Compliance", url: "/org-owner/compliance" },
            { title: "Compliance Check", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance Check",
          rule: "editor",
        },
      },
      {
        path: "drugbook/setup",
        name: "OrgOwnerDrugbook",
        component: () =>
          import("@/views/org-owner/drug-book/SetUpDrugbook.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook",
          rule: "editor",
        },
      },
      {
        path: "drugbook/setup/clinic",
        name: "OrgOwnerDrugbookTransfer",
        component: () =>
          import("@/views/org-owner/drug-book/TransferClinicDrugbook.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook | Set up",
          rule: "editor",
        },
      },
      {
        path: "drugbook",
        name: "OrgOwnerDrugbookLanding",
        component: () =>
          import("@/views/org-owner/drug-book/Drugbook.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook",
          rule: "editor",
        },
      },
      {
        path: "drugbook/entries",
        name: "OrgOwnerDrugbookEntries",
        component: () =>
          import("@/views/org-owner/drug-book/drugbookEntries.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook | Outstanding",
          rule: "editor",
        },
      },
      /** CONSENT FORM ROUTE STARTED */
      {
        path: "consent-forms",
        name: "OrgOwnerConsentForms",
        component: () => import("@/views/general-component/consent-forms/ConsentForms.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
        },
      },
      {
        path: "consent-forms/:consentId/details",
        name: "OrgOwnerConsentFormsDetails",
        component: () => import("@/views/general-component/consent-forms/ConsentFormsName.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/notice-board" },
            { title: "Consent Form", url: "/org-owner/consent-forms" },
            { title: "Questions", active: true },
          ],
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
        },
      },
      {
        path: "consent-forms/create",
        name: "OrgOwnerConsentFormsCreate",
        component: () => import("@/views/general-component/consent-forms/ConsentFormsName.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/notice-board" },
            { title: "Consent Form", url: "/org-owner/consent-forms" },
            { title: "Questions", active: true },
          ],
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
        },
      },
      {
        path: "consent-forms/:consentId/question/create",
        name: "OrgOwnerConsentFormsCreateQuestion",
        component: () => import("@/views/general-component/consent-forms/CreateConsentFormQuestions.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/notice-board" },
            { title: "Consent Form", url: "/org-owner/consent-forms" },
            { title: "Create", active: true },
          ],
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
        },
      },
      {
        path: "consent-forms/:consentId/question/:questionId/update",
        name: "OrgOwnerConsentFormsUpdateQuestion",
        component: () => import("@/views/general-component/consent-forms/CreateConsentFormQuestions.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/notice-board" },
            { title: "Consent Form", url: "/org-owner/consent-forms" },
            { title: "Update", active: true },
          ],
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
        },
      },
      /** CONSENT FORM ROUTE ENDED */
      /**INSIGHTS ROUTE STARTED */
      // {
      //   path: "insights",
      //   name: "OrgOwnerInsights",
      //   component: () =>
      //     import("@/views/superAdmin/insights/Insights.vue"),
      //   meta: {
      //     pageTitle: "Fresh Clinics | Insights",
      //     rule: "editor",
      //   },
      // },
      // {
      //   path: "clinic-dashboard",
      //   name: "OrgOwnerClinicDashboard",
      //   component: () => import("@/views/superAdmin/clinic-dashboard-v2/ClinicDashboard.vue"),
      //   meta: {
      //     pageTitle: "Fresh Clinics | Clinic Dashboard",
      //     rule: "editor",
      //   },
      // },
      {
        path: "insights/nurses",
        name: "OrgOwnerInsightsNurses",
        component: () => import("@/views/superAdmin/insights/Nurses.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/notice-board" },
            { title: "Insights", url: "/org-owner/insights" },
            { title: "Nurses", active: true },
          ],
          pageTitle: "Fresh Clinics | Insights - Nurses",
          rule: "editor",
        },
      },
      {
        path: "insights/patient-trends",
        name: "OrgOwnerInsightsPatients",
        component: () =>
          import("@/views/superAdmin/insights/PatientTrends.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/notice-board" },
            { title: "Insights", url: "/org-owner/insights" },
            { title: "Patients", active: true },
          ],
          pageTitle: "Fresh Clinics | Insights - Patient Trends",
          rule: "editor",
        },
      },
      {
        path: "insights/products",
        name: "OrgOwnerInsightsProducts",
        component: () => import("@/views/superAdmin/insights/Products.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/notice-board" },
            { title: "Insights", url: "/org-owner/insights" },
            { title: "Products", active: true },
          ],
          pageTitle: "Fresh Clinics | Insights - Products",
          rule: "editor",
        },
      },
      {
        path: "insights/clinics",
        name: "OrgOwnerInsightClinics",
        component: () => import("@/views/superAdmin/insights/Clinics.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/notice-board" },
            { title: "Insights", url: "/org-owner/insights" },
            { title: "Clinics", active: true },
          ],
          pageTitle: "Fresh Clinics | Insight - Clinics",
          rule: "editor",
        },
      },
      {
        path: "insights/treatment-area",
        name: "OrgOwnerInsightTreatmentAreas",
        component: () =>
          import("@/views/superAdmin/insights/TreatmentAreas.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/org-owner/notice-board" },
              { title: "Insights", url: "/org-owner/insights" },
              { title: "Treatment Area", active: true },
            ],
            pageTitle: "Fresh Clinics | Insight - Treatment Area",
            rule: "editor",
          },
          children: [
            {
              path: "single-vs-multiple",
              name: "OrgOwnerInsightSingle",
              component: () =>
                import("@/views/superAdmin/insights/SingleVSMultiple.vue"),
              meta: {
                breadcrumb: [
                  { title: "Home", url: "/org-owner/notice-board" },
                  { title: "Insights", url: "/org-owner/insights" },
                  { title: "Single VS Multiple Treatment", active: true },
                ],
                pageTitle: "Fresh Clinics | Insight - Single Vs. Multiple Treatments",
                rule: "editor",
              },
              alias: "/",
            },
            {
              path: "all/treatments-per-treatment-area",
              name: "OrgOwnerAverageTreatmentsPerTreatmentAreaAll",
              component: () =>
                import(
                  "@/views/superAdmin/insights/TreatmentsPerTreatmentArea.vue"
                ),
              meta: {
                breadcrumb: [
                  { title: "Home", url: "/org-owner/notice-board" },
                  { title: "Insights", url: "/org-owner/insights" },
                  {
                    title: "Number of Treatments per Treatment Area",
                    active: true,
                  },
                ],
                pageTitle: "Fresh Clinics | Insight - Treatment",
                rule: "editor",
              },
            },
            {
              path: "toxin/treatments-per-treatment-area",
              name: "OrgOwnerAverageTreatmentsPerTreatmentAreaToxin",
              component: () =>
                import(
                  "@/views/superAdmin/insights/TreatmentsPerTreatmentArea.vue"
                ),
              meta: {
                breadcrumb: [
                  { title: "Home", url: "/org-owner/notice-board" },
                  { title: "Insights", url: "/org-owner/insights" },
                  {
                    title: "Number of Treatments per Treatment Area",
                    active: true,
                  },
                ],
                pageTitle: "Fresh Clinics | Insight - Treatment",
                rule: "editor",
              },
            },
            {
              path: "filler/treatments-per-treatment-area",
              name: "OrgOwnerAverageTreatmentsPerTreatmentAreaFiller",
              component: () =>
                import(
                  "@/views/superAdmin/insights/TreatmentsPerTreatmentArea.vue"
                ),
              meta: {
                breadcrumb: [
                  { title: "Home", url: "/org-owner/notice-board" },
                  { title: "Insights", url: "/org-owner/insights" },
                  {
                    title: "Number of Treatments per Treatment Area",
                    active: true,
                  },
                ],
                pageTitle: "Fresh Clinics | Insight - Treatment",
                rule: "editor",
              },
            },
            {
              path: "toxin/average-treatment-by-gender-and-age",
              name: "OrgOwnerAverageTreatmentByGenderAndAge",
              component: () =>
                import(
                  "@/views/superAdmin/insights/AverageTreatmentByGenderAndAge.vue"
                ),
              meta: {
                breadcrumb: [
                  { title: "Home", url: "/org-owner/notice-board" },
                  { title: "Insights", url: "/org-owner/insights" },
                  {
                    title: "Average Treatment By Gender And Age (TOXIN)",
                    active: true,
                  },
                ],
                pageTitle: "Fresh Clinics | Insight - Treatment",
                rule: "editor",
              },
            },
            {
              path: "toxin/average-treatment-by-age",
              name: "OrgOwnerAverageTreatmentByAge",
              component: () =>
                import("@/views/superAdmin/insights/AverageTreatmentByAge.vue"),
              meta: {
                breadcrumb: [
                  { title: "Home", url: "/org-owner/notice-board" },
                  { title: "Insights", url: "/org-owner/insights" },
                  {
                    title: "Average Treatment By Age (TOXIN)",
                    active: true,
                  },
                ],
                pageTitle: "Fresh Clinics | Insight - Treatment",
                rule: "editor",
              },
            },
            {
              path: "all/average-treatment-by-area",
              name: "OrgOwnerAverageTreatmentByAreaAll",
              component: () =>
                import(
                  "@/views/superAdmin/insights/AverageTreatmentByArea.vue"
                ),
              meta: {
                breadcrumb: [
                  { title: "Home", url: "/org-owner/notice-board" },
                  { title: "Insights", url: "/org-owner/insights" },
                  { title: "Average Treatment By Area (ALL)", active: true },
                ],
                pageTitle: "Fresh Clinics | Insight - Treatment",
                rule: "editor",
              },
            },
            {
              path: "filler/average-treatment-by-area",
              name: "OrgOwnerAverageTreatmentByAreaFiller",
              component: () =>
                import(
                  "@/views/superAdmin/insights/AverageTreatmentByArea.vue"
                ),
              meta: {
                breadcrumb: [
                  { title: "Home", url: "/org-owner/notice-board" },
                  { title: "Insights", url: "/org-owner/insights" },
                  { title: "Average Treatment By Area (FILLER)", active: true },
                ],
                pageTitle: "Fresh Clinics | Insight - Treatment",
                rule: "editor",
              },
            },
            {
              path: "toxin/average-treatment-by-area",
              name: "OrgOwnerAverageTreatmentByAreaToxin",
              component: () =>
                import(
                  "@/views/superAdmin/insights/AverageTreatmentByArea.vue"
                ),
              meta: {
                breadcrumb: [
                  { title: "Home", url: "/org-owner/notice-board" },
                  { title: "Insights", url: "/org-owner/insights" },
                  { title: "Average Treatment By Area (TOXIN)", active: true },
                ],
                pageTitle: "Fresh Clinics | Insight - Treatment",
                rule: "editor",
              },
            },
            {
              path: "filler/average-treatment-by-gender-and-age",
              name: "OrgOwnerAverageTreatmentByGenderAndAgeFiller",
              component: () =>
                import(
                  "@/views/superAdmin/insights/AverageTreatmentByGenderAndAge.vue"
                ),
              meta: {
                breadcrumb: [
                  { title: "Home", url: "/org-owner/notice-board" },
                  { title: "Insights", url: "/org-owner/insights" },
                  {
                    title: "Average Treatment By Gender And Age (FILLER)",
                    active: true,
                  },
                ],
                pageTitle: "Fresh Clinics | Insight - Treatment",
                rule: "editor",
              },
            },
            {
              path: "filler/average-treatment-by-age",
              name: "OrgOwnerAverageTreatmentByAgeFiller",
              component: () =>
                import(
                  "@/views/superAdmin/insights/AverageTreatmentByAge.vue"
                ),
              meta: {
                breadcrumb: [
                  { title: "Home", url: "/org-owner/notice-board" },
                  { title: "Insights", url: "/org-owner/insights" },
                  {
                    title: "Average Treatment By Age (FILLER)",
                    active: true,
                  },
                ],
                pageTitle: "Fresh Clinics | Insight - Treatment",
                rule: "editor",
              },
            },
            {
              path: "filler/treatment-by-nurse",
              name: "OrgOwnerInsightsTreatmentByNurseFiller",
              component: () =>
                import(
                  "@/views/superAdmin/insights/TreatmentAreaByNurse.vue"
                ),
              meta: {
                breadcrumb: [
                  { title: "Home", url: "/org-owner/dashboard" },
                  { title: "Insights", url: "/org-owner/insights" },
                  {
                    title: "Treatment Area by Nurse",
                    active: true,
                  },
                ],
                pageTitle: "Fresh Clinics | Insight - Treatment",
                rule: "editor",
              },
            },
            {
              path: "toxin/treatment-by-nurse",
              name: "OrgOwnerInsightsTreatmentByNurse",
              component: () =>
                import(
                  "@/views/superAdmin/insights/TreatmentAreaByNurse.vue"
                ),
              meta: {
                breadcrumb: [
                  { title: "Home", url: "/org-owner/dashboard" },
                  { title: "Insights", url: "/org-owner/insights" },
                  {
                    title: "Treatment Area by Nurse",
                    active: true,
                  },
                ],
                pageTitle: "Fresh Clinics | Insight - Treatment",
                rule: "editor",
              },
            },
          ],
      },
      {
        path: "insights/appointments",
        name: "OrgOwnerInsightClinics",
        component: () =>
          import("@/views/superAdmin/insights/Appointments.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/notice-board" },
            { title: "Insights", url: "/org-owner/insights" },
            { title: "Appointments", active: true },
          ],
          pageTitle: "Fresh Clinics | Insight - Appointments",
          rule: "editor",
        },
      },
      /**INSIGHTS ROUTE ENDED */
      {
        path: "inventory",
        name: "OrgOwnerInventory",
        component: () => import("@/views/org-owner/inventory/Inventory.vue"),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/org-owner/notice-board"},
            {title: "Inventory", active: true }
          ],
          pageTitle: "Fresh Clinics | Insight - Inventory",
          rule: "editor",
        },
      },
      {
        path: "clinics/:clinicId/edit",
        name: "OrgOwnerClinicEdit",
        component: () => import("@/views/admin/clinics/clinicsEdit.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Clinics", url: "/org-owner/clinics" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Clinics | Edit",
          rule: "editor",
        },
      },
      {
        path: "basic-user/:basicUserId",
        name: "OrgOwnerBasicEditRoute",
        component: () =>
          import("@/views/org-owner/basic-user/BasicDetails.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Basic" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Basic | Edit",
          rule: "editor",
        },
      },
      {
        path: "nurses/:nurseId",
        name: "OrgOwnerNurseDetails",
        component: () => import("@/views/org-owner/nurses/NurseDetails.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Nurses", url: "/org-owner/nurses" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Nurses | Edit",
          rule: "editor",
        },
      },
      {
        path: "doctors/:doctorId/edit",
        name: "OrgOwnerDoctorEdit",
        component: () => import("@/views/org-owner/doctors/doctorEdit.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Doctors", url: "/org-owner/doctors" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Doctors | Edit",
          rule: "editor",
        },
      },
      {
        path: "doctor-clinics/:franchiseId",
        name: "OrgOwnerDoctorClinicsEdit",
        component: () =>
          import("@/views/org-owner/doctor-clinics/viewFranchise.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Doctor Clinics", url: "/org-owner/doctor-clinics" },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Doctor Clinics | Edit",
          rule: "editor",
        },
      },
      {
        path: "derma-specialist/:franchiseId",
        name: "OrgOwnerDermaSpecialistEdit",
        component: () =>
          import("@/views/org-owner/derma-specialist/viewFranchise.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            {
              title: "Derma Specialist",
              url: "/org-owner/derma-specialist",
            },
            { title: "Edit", active: true },
          ],
          pageTitle: "Fresh Clinics | Derma Specialist | Edit",
          rule: "editor",
        },
      },
      {
        path: "shop",
        name: "OrgOwnerOrderCenter",
        component: () =>
          import("@/views/org-owner/ecommerce/OrderCenter.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/shop" },
          //   { title: "Shop", active: true },
          // ],
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      {
        path: "shop/category/:slug",
        name: "OrgOwnerOrderCenterCategoryPage",
        component: () =>
          import("@/views/org-owner/ecommerce/Category.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/shop" },
          //   { title: "Shop", active: true },
          // ],
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      // {
      //   path: "shop/category_/:slug",
      //   name: "OrgOwnerOrderCenterCategory_Page",
      //   component: () =>
      //     import("@/views/org-owner/ecommerce/SlugCategory.vue"),
      //   meta: {
      //     pageTitle: "Fresh Clinics | Shop",
      //     rule: "editor",
      //   },
      // },
      {
        path: "shop/promotions",
        name: "OrgOwnerOrderCenterPromotionsPage",
        component: () =>
          import("@/views/org-owner/ecommerce/ShopPromotions.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      {
        path: "shop/search",
        name: "OrgOwnerOrderCenterSearch",
        component: () =>
          import("@/views/org-owner/ecommerce/SearchResult.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/shop" },
          //   { title: "Shop", active: true },
          // ],
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      {
        path: "shop/brands/:slug",
        name: "OrgOwnerOrderCenterBrandPage",
        component: () =>
          import("@/views/org-owner/ecommerce/Brand.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/shop" },
          //   { title: "Shop", active: true },
          // ],
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      {
        path: "product/:id",
        name: "OrgOwnerProductDetail",
        component: () =>
          import("@/views/org-owner/ecommerce/ProductDetail.vue"),
        meta: {

          pageTitle: "Fresh Clinics | Product Detail",
          rule: "editor",
        },
      },
      {
        path: "checkout",
        name: "OrgOwnerProductCheckout",
        component: () =>
          import("@/views/org-owner/ecommerce/ClinicCheckout.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/shop" },
            { title: "Shop", url: "/org-owner/shop" },
            { title: "Product Checkout", active: true },
          ],
          pageTitle: "Fresh Clinics | Product Checkout",
          rule: "editor",
        },
      },
      {
        path: "order-complete",
        name: "OrgOwnerOrderComplete",
        component: () =>
          import("@/views/org-owner/ecommerce/ClinicOrderComplete.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/shop" },
            { title: "Shop", url: "/org-owner/shop" },
            { title: "Order Complete", active: true },
          ],
          pageTitle: "Fresh Clinics | Order Complete",
          rule: "editor",
        },
      },
      {
        path: "orders",
        name: "OrgOwnerOrderList",
        component: () =>
          import("@/views/org-owner/ecommerce/order/OrderList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/shop" },
            { title: "Orders", active: true },
          ],
          pageTitle: "Fresh Clinics | Orders",
          rule: "editor",
        },
      },
      {
        path: "orders/:orderId",
        name: "OrgOwnerOrderDetail",
        component: () =>
          import("@/views/org-owner/ecommerce/order/OrderDetail.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/shop" },
            { title: "Orders", url: "/org-owner/orders" },
            { title: "Order Detail", active: true },
          ],
          pageTitle: "Fresh Clinics | Order Detail",
          rule: "editor",
        },
      },
      {
        path: "settings/treatment-areas",
        name: "OrgOwnerTreatmentAreas",
        component: () =>
          import("@/views/franchise/settings/TreatmentArea.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Treatment Areas",
          rule: "editor",
        },
      },
      {
        path: "settings/products-list",
        name: "OrgOwnerProductsList",
        component: () =>
          import("@/views/franchise/settings/ProductsList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Treatment Areas",
          rule: "editor",
        },
      },
    ],
  },
]
