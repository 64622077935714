import jwt from '../../http/requests/auth/jwt/index.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import router from '@/router';
import httpStatusCode from 'http-status-codes';
import { use } from 'vee-validate/dist/vee-validate.minimal.esm';
import axios from '@/axios';

export default {
  getOrderList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpOrderList(data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  getOrderDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpOrderDetail(data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  getOrderDetailsIntent({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpOrderDetailIntent(data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  regenerateAttachment({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .regeneratePDF(data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  updateOrderTracking({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateHttpOrderTracking(data.orderNumber, data.body)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  updateOrderTrackingInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateHttpOrderTrackingInfo(data.orderNumber, data.body)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  sendInvoiceToClinicFromAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .sendInvoiceToClinicApi(data.id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendTrackingToClinicFromAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .sendTrackingToClinicApi(data.id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchProductsForOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpProductForOrder(data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  createOrderByAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .createOrderByAdminHttp(data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resubmitXero({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/xero/resubmit/` + id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resubmitSage({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/sage/resubmit/` + id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resendEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt.resendEmail(data).then(res=>{
        resolve(res)
      }).catch(err=>{
        reject(err)
      })
    });
  },
  resendCustomerEmail({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      jwt.resendCustomerEmail(orderId).then(res=>{
        resolve(res)
      }).catch(err=>{
        reject(err)
      })
    });
  },
  getPaginatedOrderList({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/v1/store/orders/paginate-list', { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
