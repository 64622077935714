import axios from "@/axios";

export default {
  getVoucherList({ commit },data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/store/voucher?page=` +
        data.page +
        '&limit=' +
        data.limit +
        '&search=' +
        data.search +
        '&sort=' +
        data.sort +
        '&dir=' +
        data.dir)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getVoucherHistory({ commit }, voucher_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/store/voucher/history/${voucher_id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getClinicVoucherByCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/store/voucher/fetchVoucher/${data.clinicId}`, data
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createNewVoucher({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/store/voucher/create`, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateVoucher({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/v1/store/voucher/update/${data.id}`, data.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteVoucherById({ commit }, voucher_id) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/v1/store/voucher/delete/${voucher_id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  useVoucherCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/store/voucher/useVoucherCode/${data.voucherId}`, data.payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
};
